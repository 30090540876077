import React, { Component } from "react";
import {
  ElementsConsumer,
  PaymentElement,
  ExpressCheckoutElement
} from '@stripe/react-stripe-js'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { DonationDetails } from "./DonationDetails";

export class StripePaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrors: false,
      formSubmitted: false,
      showSecurityCodeInfo: false,
      formDataComplete: false
    };
  }

  async confirmPaymentExpressCheckout(e) {
    const { donationFromProcessing, stripe, elements } = this.props;

    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }

    this.setState({ formSubmitted: true });
    
    const result = await stripe.confirmPayment({
      clientSecret: donationFromProcessing.intentClientSecret,
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: this.props.personalDetails.email
          },
        },
        return_url: window.location.href,
      },
      redirect: "if_required",
    });

    if (result.error) {
      this.setState({ formSubmitted: false, errorMsg: result.error.message, showErrors: true});
    } else {
      this.setState({ stripePaymentResponse: result });
    }
  }

  async submitPaymentElement(event) {
    if (event) {
      event.preventDefault();
    }

    const { donationFromProcessing, stripe, elements } = this.props;

    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }

    this.setState({ formSubmitted: true });

    const donorEmail = this.props.personalDetails
    ? this.props.personalDetails.email
    : null;

    const returnUrl = window.location.href + "?donorEmail=" + encodeURIComponent(donorEmail) + "&ecommerceProject=" + this.props.charityDetails.ecommerceProject + "&charityName=" + this.props.charityDetails.charity.name + "&projectName=" + this.props.charityDetails.title + "&publicId=" + this.props.charityDetails.charity.charityPublicId;
    
    const result = await stripe.confirmPayment({
      clientSecret: donationFromProcessing.intentClientSecret,
      elements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: this.props.personalDetails.email
          },
        },
        return_url: returnUrl,
      },
      redirect: "if_required",
    });

    if (result.error) {
      this.setState({ formSubmitted: false, errorMsg: result.error.message, showErrors: true});
    } else {
      this.setState({ stripePaymentResponse: result });
    }
  }

  paymentElementChange(event) {
    this.setState({ formDataComplete: event.complete });
  }

  render() {
    const { amount, currency, errorMsg, globalError, charityDetails } =
      this.props;
    const stripePaymentResponse = this.state.stripePaymentResponse;
    if (stripePaymentResponse) {
      const donorEmail = this.props.personalDetails
        ? this.props.personalDetails.email
        : null;
      const paymentResponseStatus =
        stripePaymentResponse?.last_payment_error ||
          stripePaymentResponse?.paymentIntent?.last_payment_error ||
          stripePaymentResponse?.error
          ? "error"
          : "success";

      const { contribution } = charityDetails;

      const newLocation = "/status?transaction.status=" +
        paymentResponseStatus +
        "&charityName=" +
        charityDetails.charity.name +
        "&projectName=" +
        charityDetails.title +
        "&publicId=" +
        charityDetails.charity.charityPublicId +
        "&id=" +
        stripePaymentResponse.id +
        "&donorEmail=" +
        encodeURIComponent(donorEmail) +
        "&ecommerceProject=" +
        charityDetails.ecommerceProject + (contribution ? "&redirectToCharityThankYouPage=true" : "");
      window.location.href = newLocation;
    }

    return (      
      <>
        <div className="stripe-express-checkout-container">
          <ExpressCheckoutElement options={{paymentMethods: { applePay:"always", googlePay: "always"}, layout: { maxColumns: 3, overflow: "never" }}}  onConfirm={(e) => this.confirmPaymentExpressCheckout(e)} />
        </div>
        <form onSubmit={(e) => this.submitPaymentElement(e)}>
          <div id="stripe-payment-form">
            <div
                className={
                  "global-error-container " +
                  (
                    globalError || errorMsg || this.state.showErrors
                    ? ""
                    : "hidden"
                  )
                }
                >
                <div className="error-header">
                  {globalError ? "Gateway Error" : "Validation Error"}
                </div>
                <div className="error-message">{globalError || errorMsg}</div>
            </div>
            <div id="payment-details-bubble">
              <div id="payment-details-container">
                <PaymentElement onChange={(e) => this.paymentElementChange(e)} />
                </div>
              </div>
              <button id="donate-button" type="submit" disabled={!this.state.formDataComplete || this.state.formSubmitted || stripePaymentResponse || !this.props.donationFromProcessing}>
                {!this.state.formSubmitted && !stripePaymentResponse && <>
                <img src={require("./../../images/check-white.svg")} alt="check-white-logo" />
                {charityDetails && charityDetails.ecommerceProject
                  ? "Pay "
                  : "Donate "}
                  {currency}
                  {DonationDetails.addedExtraZero(amount)}
                </>}
                {(this.state.formSubmitted || stripePaymentResponse) && "Loading..."}
              </button>
            </div>
        </form>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    donationFromProcessing: state.donationFormReducer.donationFromProcessing,
    errorMsg: state.donationFormReducer.errorMsg,
    globalError: state.donationFormReducer.globalError,
    stripePaymentResponse: state.donationFormReducer.stripePaymentResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export const InjectedStripePaymentFrom = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <StripePaymentForm stripe={stripe} elements={elements} {...props}/>
    )}
  </ElementsConsumer>
);

export default 
  connect(mapStateToProps, mapDispatchToProps)(InjectedStripePaymentFrom);
