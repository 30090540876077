import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import { DonationDetails } from "./DonationDetails";
import { PersonalDetails } from "./PersonalDetails";
import { PaymentDetails } from "./PaymentDetails";
import { StripePaymentDetails } from "./StripePaymentDetails";
import PersonalDetailsRequest from "../../models/personal-details";
import DonationDetailsRequest from "../../models/donation-details";
import { RecurringTypes } from "../../models/recurring-types";
import { PaymentProviders } from "../../models/payment-providers";
import * as CurrencySymbols from "../../models/currency-symbols";
import { Footer } from "../common/Footer";

class DonationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldSkipPayment: false,
      currentStep: 1,
      giftAid: false,
      anonymousDonation: false,
      donationDetails: {},
      amount: "",
      currency: "",
    };

    this.moveToSecondStep = this.moveToSecondStep.bind(this);
    this.moveToThirdStep = this.moveToThirdStep.bind(this);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.props.actions.loadCharityDetails(
      this.props.charityPublicId,
      this.props.projectId
    );
    const { redirectUrl } = this.props;
    localStorage.setItem("redirectUrl", redirectUrl);
  }

  moveToSecondStep(requestObject) {
    const donationDetails = new DonationDetailsRequest();
    donationDetails.amount = Number(requestObject.amount) >= 0 ? requestObject.amount : null;
    donationDetails.currency = requestObject.currency
      ? requestObject.currency
      : null;
    donationDetails.note = requestObject.message ? requestObject.message : null;
    donationDetails.source = this.props.source ? this.props.source : null;
    donationDetails.giftAidSigned = requestObject.giftAid;
    donationDetails.projectId = this.props.projectId;

    switch (requestObject.recurringType) {
      case RecurringTypes.ONE_OFF:
        donationDetails.reccuringInterval = 0;
        donationDetails.reccuringType = null;
        break;
      case RecurringTypes.MONTHLY:
        donationDetails.reccuringInterval = 1;
        donationDetails.reccuringType = "MONTH";
        break;
      case RecurringTypes.QUARTERLY:
        donationDetails.reccuringInterval = 3;
        donationDetails.reccuringType = "MONTH";
        break;
      case RecurringTypes.YEARLY:
        donationDetails.reccuringInterval = 1;
        donationDetails.reccuringType = "YEAR";
        break;
      default:
        donationDetails.reccuringInterval = 0;
        donationDetails.reccuringType = null;
    }

    this.setState({
      currentStep: 2,
      giftAid: requestObject.giftAid,
      amount: requestObject.amount,
      currency: requestObject.currency,
      anonymousDonation: requestObject.isAnonymous,
      donationDetails: donationDetails,
    });
    this.props.actions.loadPersonalOptions();
  }

  moveToThirdStep(personalDetails, reCaptchaToken, shouldSkipPayment) {
    const request = new PersonalDetailsRequest();

    request.email = personalDetails.email;
    request.firstName = personalDetails.firstName
      ? personalDetails.firstName
      : null;
    request.lastName = personalDetails.lastName
      ? personalDetails.lastName
      : null;
    request.country.name = personalDetails.country
      ? personalDetails.country
      : null;
    request.address = personalDetails.address ? personalDetails.address : null;
    request.city = personalDetails.city ? personalDetails.city : null;
    request.postCode = personalDetails.postCode
      ? personalDetails.postCode
      : null;
    request.phoneNumber = personalDetails.phone ? personalDetails.phone : null;
    request.joiningKindLink = personalDetails.joinKindlink;
    request.shareEmail = personalDetails.shareEmail;
    request.sharePhone = personalDetails.sharePhone;
    request.sharePost = personalDetails.sharePost;
    request.shareText = personalDetails.shareText;
    request.anonymousDonation = this.state.anonymousDonation;
    request.donationDetails = this.state.donationDetails;

    request.donationDetails.socialCoreDonationId =
      this.props.socialCoreDonationId;
    request.donationDetails.socialCoreOpportunityUuid =
      this.props.socialCoreOpportunityUuid;
    request.donationDetails.campaignName = this.props.campaignName;
    request.donationDetails.campaignId = this.props.campaignId;
    this.setState({ personalDetails, reCaptchaToken, currentStep: shouldSkipPayment ? this.state.currentStep : 3, shouldSkipPayment });
    this.props.actions.createDonation(request);
  }

  back() {
    this.setState({ currentStep: this.state.currentStep - 1 });
  }

  backNoCharity() {
    window.history.back();
  }

  render() {
    const UNKNOWN_TYPE = "Unknown";
    const {
      charityDetails,
      onboardProviders,
      amount,
      selectedShoppingItemName,
      source,
      socialCoreDonationId,
      currency,
      personalOptions,
      donation,
      donationFromProcessing,
      errorMsg,
      globalError,
      isCharityPremiumSubscription,
    } = this.props;

    if (charityDetails === null) {
      return (
        <div id="content">
          {browserWarning()}
          <div id="text-no-charity">
            This project is inactive. Please contact the non-profit if you wish
            to see this page.
          </div>
          <div
            className="button"
            id="button-no-charity"
            onClick={() => {
              this.backNoCharity();
            }}
          >
            Back
          </div>
          <Footer></Footer>
        </div>
      );
    }

    if (typeof charityDetails.charity === "undefined") {
      return null;
    }

    if ( charityDetailsArePresent(charityDetails) &&
      Object.keys(onboardProviders).length === 0 &&
      onboardProviders.constructor === Object &&
      typeof errorMsg === "undefined" &&
      typeof globalError === "undefined"
    ) {
      this.props.actions.loadOnboardProviders(charityDetails.charity.charityId);
      return null;
    }

    // Wait until we get Charity details loaded
    const isEmbedded = window.location !== window.parent.location;
    const parentUrl = isEmbedded ? document.referrer : document.location.href;

    // Converting nested ifs to a logical expression causes undesired side effects
    // due to OR testing the second argument when the first fails
    if (isEmbedded) {
      if(!isKindlinkUrl(parentUrl)) {
        if(charityDetailsArePresent(charityDetails) && !shareAndEmbedEnabled(charityDetails.charity)) {
          return (
            <div id="content">
            {browserWarning()}
            <div id="enable-share-and-embed">
              Feature not enabled for this charity! Contact help@kindlink.com or directly upgrade to KindLink Premium from here <a href="https://www.kindlink.com/contribution" target="_blank" rel="noopener noreferrer">https://www.kindlink.com/contribution</a>
            </div>
            <div
              className="button"
              id="button-no-charity"
              onClick={() => {
                this.backNoCharity();
              }}
            >
            Back
          </div>
          <Footer></Footer>
        </div>
          )
        }
      }
    }

    if (
      typeof donation !== "undefined" &&
      typeof errorMsg === "undefined" &&
      typeof globalError === "undefined" &&
      this.state.shouldSkipPayment
    ) {
      const donorEmail = donation.donor.email;
  
      window.location.href =
        "/status?transaction.status=" +
        "success" +
        "&charityName=" +
        charityDetails.charity.name +
        "&projectName=" +
        charityDetails.title +
        "&publicId=" +
        charityDetails.charity.charityPublicId +
        "&donorEmail=" +
        encodeURIComponent(donorEmail) +
        "&ecommerceProject=" +
        charityDetails.ecommerceProject;

      return null;
    }

    if (
      typeof donation !== "undefined" &&
      typeof donationFromProcessing === "undefined" &&
      typeof errorMsg === "undefined" &&
      typeof globalError === "undefined" &&
      !this.state.shouldSkipPayment
    ) {
      const processingRequest = {};
      processingRequest.donation = donation;
      processingRequest.donation.kindlinkCharityId =
        processingRequest.donation.project.charity.charityId;
      processingRequest.donation.socialCoreDonationId = socialCoreDonationId;
      // set the name of the enumeration, not the entire object.
      processingRequest.donation.type = UNKNOWN_TYPE;
      processingRequest.donation.source = source;
      processingRequest.donation.recurrence = this.state.donationDetails
        .reccuringInterval
        ? true
        : false;
      processingRequest.providerName = onboardProviders.provider;
      processingRequest.reCaptchaToken = this.state.reCaptchaToken;

      this.props.actions.sendDonationToDonationProcessingServer(
        processingRequest
      );
      return null;
    }

    if (
      typeof donationFromProcessing !== "undefined" &&
      typeof errorMsg === "undefined" &&
      typeof globalError === "undefined" &&
      this.state.currentStep !== 3
    ) {
      return null;
    }

    return (
      <div id="content">
        {browserWarning()}
        <div
          id="navigation"
          className={this.state.currentStep === 1 ? "hidden" : ""}
        >
          <div
            id="back-button"
            className={this.state.currentStep === 3 ? "hidden" : ""}
            onClick={() => {
              this.back();
            }}
          >
            <img
              src={require("./../../images/arrow-left.png")}
              alt="arrow-left"
            />
            <div>back</div>
          </div>
          <div id="steps">step {this.state.currentStep} of 3</div>
        </div>
        <div className={this.state.currentStep !== 1 ? "hidden" : ""}>
          <DonationDetails
            charityDetails={charityDetails}
            onboardProviders={onboardProviders}
            amount={amount}
            selectedShoppingItemName={selectedShoppingItemName}
            source={source}
            currencyFromUrl={currency}
            globalError={globalError}
            errorMsg={errorMsg}
            sendDataToParentComponent={this.moveToSecondStep}
            isCharityPremiumSubscription={isCharityPremiumSubscription}
          ></DonationDetails>
        </div>
        <div className={this.state.currentStep !== 2 ? "hidden" : ""}>
          <PersonalDetails
            charityDetails={charityDetails}
            personalOptions={personalOptions}
            giftAid={this.state.giftAid}
            globalError={globalError}
            errorMsg={errorMsg}
            donationDetails={this.state.donationDetails}
            sendDataToParentComponent={this.moveToThirdStep}
            reCaptchaKey={this.props.reCaptchaKey}
            reCaptchaVersion={this.props.reCaptchaVersion}
            currentStep={this.state.currentStep}
          ></PersonalDetails>
        </div>
        {this.state.currentStep > 2 && donation ? <PaymentStep that={this} /> : null}
        <Footer charityDetails={charityDetails}></Footer>
      </div>
    );
  }
}

function charityDetailsArePresent(details) {
  return typeof details.charity !== "undefined" && details.charity.charityId > 0;
}

function shareAndEmbedEnabled(charity) {
  if (charity && charity.tier && charity.tier.features && charity.tier.features.map(f => f.type).includes("SHARE_AND_EMBED")) {
    return true;
  }
  return false;
}

function isKindlinkUrl(input) {
  const regex = /^https:\/\/.*\.kindlink\.com/;
  return regex.test(input);
}

function browserWarning() {
  const isIE = document.documentMode;
  if (!isIE) {
    return null;
  }

  return (
    <div className="validation-error browser-warning">
      <div>
        We noticed you are using Internet Explorer. Microsoft has discontinued
        the support of this browser and this might cause issues with loading
        your pages. Please switch to another modern browser e.g. Edge, Chrome,
        Mozilla, Safari.
      </div>
    </div>
  );
}

function PaymentStep(params) {
  const props = params.that.props;
  const {
    donation,
    charityDetails,
    onboardProviders,
    donationFromProcessing,
    stripePaymentResponse,
    errorMsg,
    globalError,
    stripePromise
  } = props;

  if (onboardProviders.provider === PaymentProviders.STRIPE) {
    return (
      <div>
        <StripePaymentDetails
          amount={donation.amount}
          currencySymbol={CurrencySymbols.CurrencySymbols[donation.currency]}
          currency={donation.currency.toLowerCase()}
          charityDetails={charityDetails}
          donationFromProcessing={donationFromProcessing}
          globalError={globalError}
          errorMsg={errorMsg}
          donationDetails={params.that.state.donationDetails}
          personalDetails={params.that.state.personalDetails}
          stripePaymentResponse={stripePaymentResponse}
          stripePromise={stripePromise}
        ></StripePaymentDetails>
      </div>
    );
  } else {
    return (
      <div>
        <PaymentDetails
          amount={donation.amount}
          currency={CurrencySymbols.CurrencySymbols[donation.currency]}
          paymentUrl={donationFromProcessing.submissionUrl}
          globalError={globalError}
          errorMsg={errorMsg}
        >
          charityDetails={charityDetails}
        </PaymentDetails>
      </div>
    );
  }
}

DonationForm.propTypes = {
  actions: PropTypes.object.isRequired,
  charityDetails: PropTypes.object,
  onboardProviders: PropTypes.object.isRequired,
  charityPublicId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  amount: PropTypes.string,
  source: PropTypes.string,
  currency: PropTypes.string,
  socialCoreDonationId: PropTypes.string,
  socialCoreOpportunityUuid: PropTypes.string,
  campaignId: PropTypes.string,
  campaignName: PropTypes.string,
  personalOptions: PropTypes.object,
  donation: PropTypes.object,
  donationFromProcessing: PropTypes.object,
  stripePaymentResponse: PropTypes.object,
  errorMsg: PropTypes.string,
  globalError: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    charityDetails: state.donationFormReducer.ch,
    onboardProviders: state.donationFormReducer.providers,
    personalOptions: state.donationFormReducer.personalOptions,
    donation: state.donationFormReducer.donation,
    donationFromProcessing: state.donationFormReducer.donationFromProcessing,
    donationDetails: state.donationFormReducer.donationDetails,
    stripePaymentResponse: state.donationFormReducer.stripePaymentResponse,
    errorMsg: state.donationFormReducer.errorMsg,
    globalError: state.donationFormReducer.globalError,
  };
}

DonationForm.defaultProps = {
  charityDetails: {},
  onboardProviders: {},
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DonationForm);
